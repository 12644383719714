<template>
  <section id="portfolio">
    <div id="gallery">
      <router-link to="/portfolio/amgen">
        <PortfolioItem
          background-image-file-name="amgen-background.jpg"
          logo="amgen-logo.svg"
          :background-position-x="10"
          :background-position-y="0"
          :workTitles="workTitles.amgen"></PortfolioItem>
      </router-link>
      <router-link to="/portfolio/bayer">
        <PortfolioItem
          background-image-file-name="bayer-background.jpg"
          logo="bayer-logo.svg"
          :background-position-x="55"
          :background-position-y="0"
          :workTitles="workTitles.bayer"></PortfolioItem>
      </router-link>
      <router-link to="/portfolio/takeda">
        <PortfolioItem
          background-image-file-name="takeda-background.jpg"
          logo="takeda-logo.svg"
          :background-position-x="70"
          :background-position-y="0"
          :workTitles="workTitles.takeda"></PortfolioItem>
      </router-link>
      <router-link to="/portfolio/vifor-pharma">
        <PortfolioItem
          background-image-file-name="vifor-pharma-background.jpg"
          logo="vifor-pharma-logo.svg"
          :background-position-x="50"
          :background-position-y="0"
          :workTitles="workTitles.viforPharma"></PortfolioItem>
      </router-link>
      <router-link to="/portfolio/sandoz">
        <PortfolioItem
          background-image-file-name="sandoz-background.jpg"
          logo="sandoz-logo.svg"
          :background-position-x="50"
          :background-position-y="0"
          :workTitles="workTitles.sandoz"></PortfolioItem>
      </router-link>
      <router-link to="/portfolio/shire">
        <PortfolioItem
          background-image-file-name="shire-background.jpg"
          logo="shire-logo.svg"
          :background-position-x="46"
          :background-position-y="0"
          :workTitles="workTitles.shire"></PortfolioItem>
      </router-link>
      <router-link to="/portfolio/baxter">
        <PortfolioItem
          background-image-file-name="baxter-background.jpg"
          logo="baxter-logo.svg"
          :background-position-x="32"
          :background-position-y="0"
          :workTitles="workTitles.baxter"></PortfolioItem>
      </router-link>
      <router-link to="/portfolio/pfizer">
        <PortfolioItem
          background-image-file-name="pfizer-background.jpg"
          logo="pfizer-logo.svg"
          :background-position-x="50"
          :background-position-y="0"
          :workTitles="workTitles.pfizer"></PortfolioItem>
      </router-link>
    </div>
  </section>
</template>
<script>
import PortfolioItem from '@/components/Portfolio/PortfolioItem.vue'

import { gsap } from 'gsap/all'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import { TextPlugin } from 'gsap/TextPlugin'
import '../assets/scripts/blur'
import VanillaTilt from 'vanilla-tilt'

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CSSRulePlugin, TextPlugin)

export default {
  name: 'portfolio-item',
  data() {
    return {
      oddTL: null,
      evenTL: null,
      workTitles: {
        amgen: {
          1: 'iPad Voting Activity',
          2: 'Live Summit',
          3: 'Virtual Summit',
          4: 'Hosting/Presentation',
        },
        bayer: {
          1: 'Live Summit',
          2: 'Keypad Voting Activity',
          3: 'iPad Voting Activity',
          4: 'Presentation Speaker Training',
          5: 'Team Training',
          6: 'Hosting/Presentation',
        },
        takeda: {
          1: 'Team Training',
          2: 'Live Summit',
          3: 'iPad Voting Activity',
          4: 'Hosting/Presentation',
        },
        viforPharma: {
          1: 'Booth Team Training',
          2: 'Keypad Voting Activity',
          3: 'Hosting/Presentation',
        },
        sandoz: {
          1: 'Booth Team Training',
          2: 'Keypad Voting Activity',
          3: 'iPad Voting Activity',
          4: 'Hosting/Presentation',
        },
        shire: {
          1: 'Keypad Voting Activity',
        },
        baxter: {
          1: 'Keypad Voting Activity',
          2: 'Hosting/Presentation',
        },
        pfizer: {
          1: 'Live Patient Enactment',
          2: 'Knowledge Forum Activity',
          3: 'Hosting/Presentation',
        },
      },
    }
  },
  components: { PortfolioItem },
  methods: {
    showPortfolioItem(batch) {
      batch.forEach(function (target) {
        target.tl.play()
      })
    },

    hidePortfolioItem(batch) {
      batch.forEach(function (target, i) {
        gsap.delayedCall(i * 0.05, function () {
          target.tl.reverse()
        })
      })
    },
  },
  activated() {
    const portfolioItems = gsap.utils.toArray('.portfolio-item')

    // Animate each portfolio item on appear.

    portfolioItems.forEach((item) => {
      item.tl = gsap
        .timeline({
          immediateRender: false,
          paused: true,
          delay: 0.3,
          onStart: () => {
            VanillaTilt.init(item, {
              max: 3,
              speed: 200,
              perspective: 400,
              glare: true,
              'max-glare': 0.2,
              gyroscope: false,
              scale: 1.05,
            })
          },
          callbackScope: item,
          onReverseComplete: function () {
            // If the portfilio item disappesars remove the tilt effect.
            if (this.vanillaTilt) {
              this.vanillaTilt.destroy()
            }
          },
        })
        .fromTo(
          // Scale BG div from bottom left to top right.
          item.querySelector('.portfolio-item-mis-bg'),
          {
            alpha: 1,
            scale: 0,
          },
          {
            scale: 1,
            transformOrigin: 'bottom left',
            stagger: 0.2,
            duration: 0.3,
            delay: 0,
            rotation: 0.001,
            force3D: true,
          },
          '0'
        )
        .set(item.querySelector('.portfolio-item-mis-fg'), {
          alpha: 1,
        })
        .to(
          [
            item.querySelector('.portfolio-item-background'),
            item.querySelector('.portfolio-item-logo'),
          ],
          {
            alpha: 1,
            duration: 0,
          },
          '+=0'
        )
        .fromTo(
          item.querySelector('.portfolio-item-mis-fg'),
          { x: '-=70%', y: '-=-50%' },
          {
            duration: 0.9,
            stagger: 0.2,
            scaleY: 0,
            force3D: true,
          },
          '+=0.0'
        )
        .set(
          [
            item.querySelector('.portfolio-item-mis-fg'),
            item.querySelector('.portfolio-item-mis-bg'),
          ],
          {
            alpha: 0,
            duration: 0,
          },
          '+=0.0'
        )
        .to(
          item,
          {
            overflow: 'visible',
            duration: 0,
          },
          '+=0.0'
        )
        .to(item.parentElement.querySelector('.item-description'), {
          alpha: 1,
          duration: 0,
        })

        // Fade in the item description list.
        .fromTo(
          item.parentElement.querySelector('.item-description')
            .firstElementChild.children,
          {
            alpha: 0,
          },
          {
            alpha: 1,
            stagger: 0.05,
          }
        )
        // .fromTo('.portfolio-item-background', { z: -100 }, { z: -50 })
        .fromTo(
          item.querySelector('.portfolio-item-logo'),
          { z: 0 },
          {
            x: 0,
            y: 0,
            z: 50,
            duration: 0.4,
            rotation: 0.001,
            force3D: true,
          }
        )
        .to(
          item.querySelector('.portfolio-item-background-blur'),
          {
            opacity: 0.8,
            duration: 0.2,
            stagger: 0.2,
          },
          '-=0.5'
        )
    })

    ScrollTrigger.batch('.portfolio-item', {
      batchMax: () => {
        try {
          let galleryWidth = document.getElementById('gallery').offsetWidth
          let portfolioItemWidth = document.querySelector(
            '.portfolio-item-wrapper'
          ).offsetWidth
          let numberOfItemsToShow = Math.floor(
            galleryWidth / portfolioItemWidth
          )
          if (numberOfItemsToShow !== null) {
            return numberOfItemsToShow
          } else {
            return 1
          }
        } catch {
          return 1
        }
      },
      interval: 0.1,
      onEnter: this.showPortfolioItem,
      onLeaveBack: this.hidePortfolioItem,
      start: 'center bottom',
      end: 'bottom -50%',
      markers: false,
    })

    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 800px)': () => {
        // setup animations and ScrollTriggers for screens 800px wide or greater (desktop) here...
        // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
        this.oddTL = gsap
          .timeline({
            immediateRender: false,
            scrollTrigger: {
              trigger: '#gallery',
              id: 'slideOddPortfolioItems',
              // start: "top bottom", // the default values
              // end: "bottom top",
              scrub: 2,
            },
          })
          .to('#gallery > a:nth-child(odd)', {
            force3D: true,
            yPercent: 30,
            ease: 'none',
          })

        this.evenTL = gsap
          .timeline({
            immediateRender: false,
            scrollTrigger: {
              trigger: '#gallery',
              id: 'slideEvenPortfolioItems',
              // start: "top bottom", // the default values
              // end: "bottom top",
              scrub: 2,
            },
          })
          .to('#gallery > a:nth-child(even)', {
            force3D: true,
            yPercent: -20,
            ease: 'none',
          })
      },

      // mobile
      '(max-width: 799px)': () => {
        // The ScrollTriggers created inside these functions are segregated and get
        // reverted/killed when the media query doesn't match anymore.
      },

      // all
      all: function () {
        // ScrollTriggers created here aren't associated with a particular media query,
        // so they persist.
      },
    })
  },
  deactivated() {
    const killTimeline = (timeline) => {
      const targets = timeline.getChildren()

      timeline.kill()

      for (let i = 0; i < targets.length; i++) {
        if (targets[i].targets().length) {
          gsap.set(targets[i].targets(), { clearProps: 'all' })
        }
      }
    }

    const portfolioItems = gsap.utils.toArray('.portfolio-item')

    portfolioItems.forEach((item) => {
      gsap.delayedCall(2, () => killTimeline(item.tl))
    })
    ScrollTrigger.refresh(true)
  },
}
</script>
<style lang="scss" scoped>
.statement {
  font-size: 1.6vh;
  float: left;
  clear: both;
  position: relative;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  color: rgb(204, 204, 204);
}
#portfolio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin: 12vh auto;
}
#gallery {
  position: relative;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 auto;
  padding-left: 0vh;
  top: 3vh;
  height: 100%;

  @media #{$mq-tiny} {
    width: 100%;
    margin-left: 0%;
    padding-left: 0vh;
    top: 4vh;
  }

  @media #{$mq-small} {
    width: 100%;
    top: 1vh;
  }

  @media #{$mq-medium} {
    width: 90%;
  }

  @media #{$mq-large} {
    width: 80%;
  }

  @media #{$mq-xlarge} {
    width: 70%;
    top: 5vh;
  }

  /* Portrait */
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    width: 100%;
  }

  /* Landscape */
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    width: 100%;
  }
  // @media all and (width: 428px) and (height: 926px) and (orientation: landscape) {
  //   width: 10%;
  //   color: pink;
  // }
}
#gallery > a:nth-child(2n + 1) {
  top: 0px;
  position: relative;
}

#gallery > a:nth-child(2n) {
  top: 0px;

  @media #{$mq-tiny} {
    top: 0px;
  }
  @media #{$mq-small} {
    top: 70px;
  }
  @media #{$mq-medium} {
    top: 150px;
  }

  @media #{$mq-large} {
    top: 150px;
  }

  @media #{$mq-xlarge} {
    top: 150px;
  }

  @media #{$mq-xxlarge} {
    top: 150px;
  }
}

#gallery > a {
  margin-bottom: calc(100vh - 90vh);
  position: relative;
}
</style>
